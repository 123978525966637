// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: "Open Sans", sans-serif;
    background-color: #040404;
    color: #fff;
    position: relative;
    background: transparent;
  }
  
  body::before {
    content: "";
    position: fixed;
    background: #040404 url("https://i.postimg.cc/vZVDJ2JW/11.jpg") top right no-repeat;
    background-size: cover;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    z-index: -1;
  }
  
  @media (min-width: 1024px) {
    body::before {
      background-attachment: fixed;
    }
  }
  
  a {
    color: #18d26e;
    text-decoration: none;
  }
  
  a:hover {
    color: #35e888;
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,mFAAmF;IACnF,sBAAsB;IACtB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,aAAa;IACb,WAAW;EACb;;EAEA;IACE;MACE,4BAA4B;IAC9B;EACF;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;;;;;;IAME,kCAAkC;EACpC","sourcesContent":["body {\n    font-family: \"Open Sans\", sans-serif;\n    background-color: #040404;\n    color: #fff;\n    position: relative;\n    background: transparent;\n  }\n  \n  body::before {\n    content: \"\";\n    position: fixed;\n    background: #040404 url(\"https://i.postimg.cc/vZVDJ2JW/11.jpg\") top right no-repeat;\n    background-size: cover;\n    left: 0;\n    right: 0;\n    top: 0;\n    height: 100vh;\n    z-index: -1;\n  }\n  \n  @media (min-width: 1024px) {\n    body::before {\n      background-attachment: fixed;\n    }\n  }\n  \n  a {\n    color: #18d26e;\n    text-decoration: none;\n  }\n  \n  a:hover {\n    color: #35e888;\n    text-decoration: none;\n  }\n  \n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6 {\n    font-family: \"Raleway\", sans-serif;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
